import { Alert, Container, FormSubmit, ValidatedInput } from 'components';
import { EmailRegExp } from 'consts';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { AuthThunks } from 'modules/authentication';
import { AuthLogo } from 'modules/navigation';
import { AppDispatch } from 'modules/redux-store';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { auth, spacers } from 'styles';

interface State {
  loading: boolean;
  error?: string;
  success?: boolean;
}

const ForgotPassword: React.FC = () => {
  const { formatMessage } = useIntl();
  const [formState, setFormState] = useState<State>({
    loading: false,
  });
  const methods = useForm<{ email: string }>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Container className={auth.loginFlex}>
      <AuthLogo />

      <div css={auth.form}>
        <div css={auth.formTitle}>
          <h2 css={[spacers.bottom.medium]}>
            <FormattedMessage id="forgotten-password.title" />
          </h2>

          <p>
            <FormattedMessage id="forgotten-password.description" />
          </p>
        </div>

        {formState.error && <Alert type="error" text={formState.error} />}
        {formState.success && (
          <Alert
            type="success"
            text={formatMessage({ id: 'forgotten-password.alert.success' })}
          />
        )}

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ValidatedInput
              name="email"
              label={formatMessage({
                id: 'shared.input.email.label',
              })}
              placeholder={formatMessage({
                id: 'shared.input.email.placeholder',
              })}
              validationObject={{
                required: formatMessage({
                  id: 'shared.validation.required',
                }),
                pattern: {
                  value: EmailRegExp,
                  message: formatMessage({
                    id: 'shared.validation.email.format',
                  }),
                },
              }}
            />

            <FormSubmit
              text={formatMessage({ id: 'forgotten-password.form.submit' })}
              stateIsChanging={formState.loading}
            />
          </form>
        </FormProvider>
      </div>

      <p>
        &copy; {new Date().getFullYear()}{' '}
        <FormattedMessage id="shared.copyright" />
      </p>
    </Container>
  );

  async function onSubmit(data: { email: string }) {
    setFormState({
      loading: true,
    });

    const error = await dispatch(AuthThunks.sendPasswordResetLink(data.email));

    if (error) {
      setFormState({
        success: false,
        loading: false,
        error,
      });
      return;
    }

    setFormState({
      error: '',
      loading: false,
      success: true,
    });
  }
};

export default ForgotPassword;
